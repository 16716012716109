<template>
    <section id="welcome">
        <section-header
            header="Education"
            sub-header="A better teaching experience for everyone"
            text="Find out how both educators and students benefit from Nuvolos"
        />
        <v-container :pt-5="$vuetify.breakpoint.mdAndUp" px-0 pb-0>
            <v-row>
                <v-col v-for="(feature, i) in features" :key="i" cols="12" md="6">
                    <v-row class="pa-0 ma-0 text-center text-sm-left" justify="center" align="center" tag="section">
                        <v-col>
                            <v-icon :color="feature.color" size="56" v-text="feature.icon" />
                        </v-col>
                        <v-col cols="12" md="10">
                            <h3 class="title font-weight-regular mb-3 mt-2" v-text="feature.header" />
                            <p class="body-1 font-weight-light" v-text="feature.text" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
export default {
    data: () => ({
        features: [
            {
                icon: 'mdi-web',
                color: 'blue-grey',
                header: 'Browser based',
                text: 'Nuvolos is directly accessible by any web connected device, no setup required.'
            },
            {
                icon: 'mdi-google-circles-extended',
                color: 'primary',
                header: 'Complete environments',
                text:
                    'Nuvolos provides a single interface for working with code, data (including SQL tables) and scientific applications (e.g. JupyterLab, RStudio).'
            },
            {
                icon: 'mdi-cellphone-link',
                color: 'teal',
                header: 'Identical Configurations',
                text: 'All course participants use the environment set up by the instructor, no time wasted by installation and debugging.'
            },
            {
                icon: 'share',
                color: 'red lighten-2',
                header: 'Simple sharing',
                text: 'Incrementally share code and data with students, through an effortless "push" mechanism, no knowledge of git required.'
            },
            {
                icon: 'camera_alt',
                color: 'cyan darken-2',
                header: 'Snapshots',
                text: 'Users can create snapshots of their environment and see how their code changed over time.'
            },
            {
                icon: 'assignment',
                color: 'green',
                header: 'Assignments (upcoming)',
                text: 'Distribute, collect and grade assignment directly in Nuvolos, simplifying the workflow of students and instructors.'
            }
        ]
    })
}
</script>
