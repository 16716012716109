var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "welcome" } },
    [
      _c("section-header", {
        attrs: {
          header: "Education",
          "sub-header": "A better teaching experience for everyone",
          text: "Find out how both educators and students benefit from Nuvolos"
        }
      }),
      _c(
        "v-container",
        {
          attrs: {
            "pt-5": _vm.$vuetify.breakpoint.mdAndUp,
            "px-0": "",
            "pb-0": ""
          }
        },
        [
          _c(
            "v-row",
            _vm._l(_vm.features, function(feature, i) {
              return _c(
                "v-col",
                { key: i, attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "pa-0 ma-0 text-center text-sm-left",
                      attrs: {
                        justify: "center",
                        align: "center",
                        tag: "section"
                      }
                    },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-icon", {
                            attrs: { color: feature.color, size: "56" },
                            domProps: { textContent: _vm._s(feature.icon) }
                          })
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "12", md: "10" } }, [
                        _c("h3", {
                          staticClass: "title font-weight-regular mb-3 mt-2",
                          domProps: { textContent: _vm._s(feature.header) }
                        }),
                        _c("p", {
                          staticClass: "body-1 font-weight-light",
                          domProps: { textContent: _vm._s(feature.text) }
                        })
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }